import React from "react";
import Allocation from "./sections/Allocation";
import Hero from "./sections/Hero";
import Mission from "./sections/Mission";
import Roadmap from "./sections/Roadmap";
import Numbers from "./sections/Numbers";
import Footer from "./sections/Footer";
import Team from "./sections/Team";
import Purchase from "./sections/Purchase";
import Watch from "./sections/Watch";
import constants from "./configs/constants";
import SalePopup from "./sections/SalePopup";

/* Refactor this, should be in another place. */
function getScrollbarWidthUtility() {
  // Creating invisible container
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
  document.body.appendChild(outer);
  // Creating inner element and placing it in the container
  const inner = document.createElement("div");
  outer.appendChild(inner);
  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);
  return scrollbarWidth;
}

// GOOGLE ANALYTICS
if (process.env.REACT_APP_ANALYTICS_TRACKING_ID?.length) {
  document.write(`<script async src="https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_ANALYTICS_TRACKING_ID}"></script>`);
  document.write(`<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', '${process.env.REACT_APP_ANALYTICS_TRACKING_ID}');
  </script>`);
}

function App() {
  const { anchorsEnum } = constants;

  return (
    <>
      <div id="modal-placeholder" />
      <video
        className="hero-video"
        style={{ width: `calc(100% - ${getScrollbarWidthUtility()}px)` }}
        autoPlay
        muted
        loop
        playsInline
        poster="/static/images/hero_bg.jpg"
      >
        <source src="https://xxxcoin.org/assets/hero.mp4" type="video/mp4" />
        <source src="https://xxxcoin.org/assets/hero.ogg" type="video/ogg" />
        <source src="https://xxxcoin.org/assets/hero.webm" type="video/webm" />
      </video>
      <div className="sections-wrapper">
        <SalePopup />
        <Hero />
        <Mission id={anchorsEnum.MISSION} />
        <Allocation id={anchorsEnum.ALLOCATION} />
        <Roadmap id={anchorsEnum.ROADMAP} />
        <Numbers id={anchorsEnum.TOKENOMICS} />
        <Team id={anchorsEnum.TEAM} />
        <Purchase id={anchorsEnum.PURCHASE} />
        <Watch />
        <Footer />
      </div>
    </>
  );
}

export default App;
