import React, { FunctionComponent } from "react";
import ProgressList, { ProgressItem, ProgressStep } from "src/components/ProgressList";
import SectionContent from "src/components/SectionContent";

const progresslistItems = [
  new ProgressItem("q1 2024", [
    new ProgressStep("Project concept originates", true),
    new ProgressStep("Discuss project idea with Blockchain experts", true),
    new ProgressStep("Research and develop project resources", true),
  ]),
  new ProgressItem("q2 2024", [
    new ProgressStep("Begin testing with the contract code", true),
    new ProgressStep("Assign brand identity to visual artist (design website images, logo) ", true),
    new ProgressStep("Finish test stage with the contract code and deploy it on Binance Smart Chain", true),
    new ProgressStep("Develop website and communication channels (Twitter, Telegram, Reddit, Facebook)", true),
    new ProgressStep("Start private sale for early adopters", true),
    new ProgressStep("Start presale for early adopters (1 or 2 stages)", true),
  ]),
  new ProgressItem("q3 2024", [
    new ProgressStep("Launch on PancakeSwap with locked liquidity", true),
    new ProgressStep("Submit details for exchange listings", true),
    new ProgressStep("Submit details for CoinMarketCap listing", true),
    new ProgressStep("Add more exchange listings"),
    new ProgressStep("Expand the devs, identity and marketing teams"),
  ]),
  new ProgressItem("q4 2024", [
    new ProgressStep("Start developing non-custodial wallet"),
    new ProgressStep("Start developing NFC payment card"),
    new ProgressStep("Surprise milestone"),
    new ProgressStep("List on a large exchange from top 100"),
    new ProgressStep("Set up a fintech company with the community participation"),
  ]),
  new ProgressItem("q1 2025", [
    new ProgressStep("Test wallet"),
    new ProgressStep("Test NFC card paired with the wallet"),
    new ProgressStep("Test community voting system inside the wallet"),
    new ProgressStep("Demo presentations for the main players in the events area from EU, USA, China, Dubai"),
  ]),
  new ProgressItem("q2 2025", [
    new ProgressStep("Launch Wallet on App Stores"),
    new ProgressStep("Launch pre-orders for the NFC Cards"),
    new ProgressStep("Surprise milestone"),
    new ProgressStep("Continue listing on top 100 exchanges"),
  ]),
];

const Roadmap: FunctionComponent<React.HTMLAttributes<HTMLElement>> = ({ ...props }) => {
  return (
    <div {...props}>
      <SectionContent
        className="section-container-empty roadmap-section-container"
        title="Mission Roadmap"
        text="We have done a great amount so far, yet we have so much more to bring!"
      >
        <ProgressList className="tw-mx-auto sm:tw-mt-10 tw-mt-4" items={progresslistItems} defaultActiveItemCount={2} />
      </SectionContent>
    </div>
  );
};

export default Roadmap;
