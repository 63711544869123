import React, { FunctionComponent } from "react";

const Line: FunctionComponent<React.HTMLAttributes<HTMLElement>> = ({ className }) => {
  return (
    <svg className={["dotted-line", className].join(" ")} width="1440" height="10" viewBox="0 0 1440 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1" y1="1" x2="1439" y2="1.00013" stroke="url(#paint0_linear)" stroke-width="2" stroke-linecap="round" stroke-dasharray="6 8" />
      <defs>
        <linearGradient id="paint0_linear" x1="0" y1="2" x2="0.0265046" y2="8.69813" gradientUnits="userSpaceOnUse">
          <stop stop-color="#ED2393" />
          <stop offset="1" stop-color="#FDBD6E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Line;
