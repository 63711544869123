import React, { FunctionComponent, useEffect, useState } from "react";

export enum CountDownVariant {
  Wide,
  Shrinked,
}

type Props = {
  toUnixTime: number;
  onTimePassed?: () => any;
  variant?: CountDownVariant;
};

const CountDown: FunctionComponent<Props & React.HTMLAttributes<HTMLElement>> = ({
  variant = CountDownVariant.Wide,
  toUnixTime,
  onTimePassed = () => {},
  ...props
}) => {
  const targetDate = new Date(toUnixTime * 1000);

  const formattedCountdownNumber = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };

  const calculateTimeLeft = (): any => {
    const diff = +targetDate - +new Date();

    if (diff < 0) {
      return 0;
    }

    return {
      days: Math.floor(diff / (1000 * 60 * 60 * 24)),
      hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((diff / 1000 / 60) % 60),
      seconds: Math.floor((diff / 1000) % 60),
    };
  };

  useEffect(() => {
    if (!timeLeft) {
      onTimePassed();
      return;
    }

    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const { days, hours, minutes, seconds } = timeLeft;

  return timeLeft ? (
    <div
      className={[
        "tw-max-h-52 tw-container tw-max-w-screen-xs tw-text-center tw-uppercase \
        tw-grid tw-grid-cols-2 tw-grid-flow-row \
        md:tw-grid-cols-4 md:tw-max-w-screen-lg",
        props.className,
      ].join(" ")}
    >
      <div className="countdown-item-container">
        <div className="countdown-item">
          <div className="countdown-item-number">{formattedCountdownNumber(days)}</div>
          <div className="countdown-item-unit">days</div>
        </div>
        <div className="countdown-separator " />
      </div>
      <div className="countdown-item-container">
        <div className="countdown-item">
          <div className="countdown-item-number">{formattedCountdownNumber(hours)}</div>
          <div className="countdown-item-unit">hours</div>
        </div>
        <div className="countdown-separator tw-hidden md:tw-block" />
      </div>
      <div className="countdown-item-container">
        <div className="countdown-item">
          <div className="countdown-item-number">{formattedCountdownNumber(minutes)}</div>
          <div className="countdown-item-unit">minutes</div>
        </div>
        <div className="countdown-separator" />
      </div>
      <div className="countdown-item-container">
        <div className="countdown-item">
          <div className="countdown-item-number">{formattedCountdownNumber(seconds)}</div>
          <div className="countdown-item-unit">seconds</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default CountDown;
