import React from "react";
import BigNumber from "bignumber.js";
import { Ifo } from "Wallet/config/constants/types";
import UnlockButton from "Wallet/components/UnlockButton";
import { getBalanceNumber, getFullDisplayBalance } from "Wallet/utils/formatBalance";
import IfoCardContribute from "./IfoCardContribute";
import ProgressBar from "src/components/ProgressBar";
import withWalletData from "Wallet/hooks/withWalletData";

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

export interface IfoCardProps {
  ifo: Ifo;
}

const WalletSection: React.FC = ({ cathegory, value, className }) => {
  return (
    <div className={["wallet-section tw-flex tw-flex-col tw-mb-3 xs:tw-flex-row xs:tw-flex-wrap xs:tw-justify-between xs:tw-mb-0", className].join(" ")}>
      <div>{cathegory}</div>
      <div className="xs:tw-font-bold xs:tw-text-white tw-text-secondary tw-font-normal">{value}</div>
    </div>
  );
};

const IfoCard: React.FC = ({ ifo, walletData, ...props }) => {
  const {
    // id,
    // name,
    // subTitle,
    // description,
    saleAmount,
    // raiseAmount,
    // projectSiteUrl,
    currencyAddress,
    tokenDecimals,
    currency,
    maxContribution,
    minContribution,
    token,
  } = ifo;

  const account = walletData.account;
  const state = walletData;
  // console.log("IFO card:", state);

  const isActive = state.status === "live";
  const isFinished = state.status === "finished" || state.finalized;

  return (
    <div {...props} className={["wallet tw-px-6 tw-py-5", props.className].join(" ")}>
      <div className="wallet-title tw-mb-6">XXX Coin - Rocket Fuel For Your Event</div>
      {state.hardCapProgress > 0 && (isActive || !isFinished) && (
        <div className="wallet-progress">
          <ProgressBar filledPercentage={state.hardCapProgress} />
          <div className="wallet-progress-text tw-mt-2 tw-mb-6">{state.hardCapProgress.toFixed(2)}% sold</div>
        </div>
      )}
      {(isActive || isFinished) && (
        <>
          <WalletSection cathegory={"Price"} value={`1 BNB = ${new BigNumber(state.tokensPerBnb).div(10 ** tokenDecimals)} ${ifo.token}`} />
          <WalletSection cathegory={"BNB Raised"} value={`${getFullDisplayBalance(new BigNumber(state.weiRaised))} BNB`} />
          <WalletSection cathegory={`Soft Cap (${getBalanceNumber(state.softCap)} BNB)`} value={`${state.softCapProgress.toFixed(2)}%`} />
          <WalletSection className="sm:tw-mb-4 xs:tw-mb-4" cathegory={`Hard Cap (${getBalanceNumber(state.hardCap)} BNB)`} value={`${state.hardCapProgress.toFixed(2)}%`} />
        </>
      )}
      {!account && <UnlockButton />}
      {(isActive || isFinished) && account && (
        <div className="tw-mt-6">
          <IfoCardContribute
            address={ifo.address[CHAIN_ID]}
            currency="BNB"
            currencyAddress={currencyAddress}
            contract={state.presaleContract}
            status={state.status}
            finalized={state.finalized}
            raisingAmount={state.hardCap}
            softCapReached={state.softCapReached}
            minContribution={minContribution}
            maxContribution={maxContribution}
          />
        </div>
      )}
      <div className="tw-mt-6">
        <WalletSection cathegory={"For Sale"} value={`${saleAmount} ${token}`} />
        <WalletSection cathegory={"Max Contribution"} value={`${maxContribution} ${currency}`} />
        <WalletSection cathegory={"Min Contribution"} value={`${minContribution} ${currency}`} />
      </div>
    </div>
  );
};

export default withWalletData(IfoCard);
