import React, { FunctionComponent } from "react";
import Line from "src/components/Line";
import PercentageCard from "src/components/PercentageCard";
import { RoundedSection } from "src/components/RoundedSection";
import SectionContent from "src/components/SectionContent";

const Allocation: FunctionComponent<React.HTMLAttributes<HTMLElement>> = ({ ...props }) => {
  return (
    <div {...props}>
      <RoundedSection imgSrc="static/images/allocation_dk_bg.png">
        <div className="tw-pt-10">
          <SectionContent title="XXX Coin allocation">
            <div className="inside-container-padding tw-container tw-max-w-screen-lg tw-text-center">
              The distribution strategy only means the amount of tokens in your wallet will forever increase as others make transactions. All you have to do is
              just <b>Keep calm and Hold on tight!</b>
            </div>
          </SectionContent>
          <div className="allocation-cards-container tw-relative">
            <Line className="bg-line lg:tw-block tw-hidden" />
            <Line className="bg-line line-top rotated-positive lg:tw-hidden sm:tw-block tw-hidden" />
            <Line className="bg-line line-bottom rotated-negative lg:tw-hidden sm:tw-block tw-hidden" />
            <div
              className="tw-mx-auto tw-mt-9 tw-w-full
              tw-grid tw-grid-rows-4 tw-gap-4
              sm:tw-grid-rows-2 sm:tw-grid-cols-2
              lg:tw-grid-rows-1 lg:tw-grid-cols-4 lg:tw-max-w-screen-lg"
            >
              <div className="tw-relative">
                <Line className="bg-line rotated-negative sm:tw-hidden tw-block" />
                <PercentageCard className="tw-mx-auto sm:tw-mx-0 sm:tw-ml-auto" percentage={5}>
                  automatically distributed to <b>holders</b>
                </PercentageCard>
              </div>
              <div className="tw-relative">
                <Line className="bg-line rotated-positive sm:tw-hidden tw-block" />
                <PercentageCard className="tw-mx-auto sm:tw-mx-0 sm:tw-mr-auto lg:tw-mt-card" percentage={3}>
                  <div className="tw-w-20">
                    automatically burned <b>forever</b>
                  </div>
                </PercentageCard>
              </div>
              <div className="tw-relative">
                <Line className="bg-line rotated-negative sm:tw-hidden tw-block" />
                <PercentageCard className="tw-mx-auto sm:tw-mx-0 sm:tw-ml-auto" percentage={1}>
                  dispatched to the <b>development crew</b>
                </PercentageCard>
              </div>
              <div className="tw-relative">
                <Line className="bg-line rotated-positive sm:tw-hidden tw-block" />
                <PercentageCard className="tw-mx-auto sm:tw-mx-0 sm:tw-mr-auto lg:tw-mt-card" percentage={1}>
                  granted to <b>XXX Philanthropy</b>
                </PercentageCard>
              </div>
            </div>
          </div>
        </div>
      </RoundedSection>
    </div>
  );
};

export default Allocation;
