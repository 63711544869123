import React, { useState } from "react";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import BigNumber from "bignumber.js";
import { Modal, Button, Flex, LinkExternal } from "@pancakeswap-libs/uikit";
import BalanceInput from "Wallet/components/Input/BalanceInput";
import { getFullDisplayBalance } from "Wallet/utils/formatBalance";
import useWeb3 from "Wallet/hooks/useWeb3";
import styled from "styled-components";

interface Props {
  currency: string;
  contract: any;
  currencyAddress?: string;
  onDismiss?: () => void;
  onContributeRequested?: () => void;
}

const CustomModal = styled(Modal)`
  box-shadow: 0px 12px 24px rgba(0, 7, 20, 0.36);
`;

const ContributeModal: React.FC<Props> = ({ currency, contract, minContribution, maxContribution, onDismiss, onContributeRequested }) => {
  const [value, setValue] = useState("");
  const [pendingTx, setPendingTx] = useState(false);
  const { account, balance } = useWallet();
  const web3 = useWeb3();
  console.log("alex: ifo balance: ", balance);
  console.log("alex: ifo currency: ", currency);
  console.log("alex: ifo contract._address: ", contract._address);
  return (
    <CustomModal title={`Contribute ${currency}`} onDismiss={onDismiss}>
      <BalanceInput
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        symbol={currency}
        max={getFullDisplayBalance(new BigNumber(balance))}
        onSelectMax={() => setValue(new BigNumber(balance).div(new BigNumber(10).pow(18)).toString())}
      />
      <Flex justifyContent="space-between" mb="24px">
        <Button variant="secondary" onClick={onDismiss} mr="8px">
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: "cornflowerblue", color: "white" }}
          disabled={
            pendingTx ||
            !new BigNumber(value).isPositive() ||
            new BigNumber(value).isGreaterThan(new BigNumber(maxContribution)) ||
            new BigNumber(value).isLessThan(new BigNumber(minContribution))
          }
          onClick={async () => {
            setPendingTx(true);
            try {
              await web3.eth.sendTransaction({
                from: account,
                to: contract._address,
                value: new BigNumber(value).times(new BigNumber(10).pow(18)).toString(),
              });
              setPendingTx(false);
              onDismiss();
              onContributeRequested();
            } catch (e) {
              console.error("Error:", e);
            } finally {
              setPendingTx(false);
            }
          }}
        >
          {pendingTx ? "Processing..." : "Confirm"}
        </Button>
      </Flex>
      <LinkExternal href="https://exchange.pancakeswap.finance/#/swap" style={{ margin: "auto" }}>
        {`Get ${currency}`}
      </LinkExternal>
    </CustomModal>
  );
};

export default ContributeModal;
