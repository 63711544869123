import React, { useState } from "react";
import { useWalletModal } from "@pancakeswap-libs/uikit";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import Button, { ButtonVariant } from "src/components/Button";
import Modal from "src/components/Modal";

const UnlockButton = (props) => {
  const { connect, reset } = useWallet();
  const [showWarning, setShowWarning] = useState(false);
  const { onPresentConnectModal } = useWalletModal(connect, reset);

  const handleModalButtonClick = () => {
    setShowWarning(false);
    onPresentConnectModal();
  };

  return [
    <Modal show={showWarning} onConfirm={onPresentConnectModal} hasCloseBtn={false}>
      <div className="tw-text-center">
        <div className="section-title">One more thing</div>
        <div className="separator-primary tw-mt-3 tw-mb-4" />
        <div className="section-text sm:tw-px-6 tw-px-0">
          <div className="tw-space-y-2">
            <p>
              To successfully unlock your wallet, please make sure it is connected to <b>Binance Smart Chain</b>.
            </p>
            <p>
              In case you’re using a <b>mobile device</b>, make sure you access the application from a <b>Dapp browser</b>.
            </p>
            <p>
              If you access our platform from a <b>desktop browser</b>, make sure you have an appropriate <b>wallet extension</b> already set.
            </p>
          </div>
          <Button
            inactiveBgColor="var(--card-primary-background)"
            className="tw-w-full tw-mt-6"
            variant={ButtonVariant.Primary}
            onClick={() => handleModalButtonClick()}
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>,
    <Button
      inactiveBgColor="var(--card-primary-background)"
      className="tw-w-full"
      variant={ButtonVariant.Primary}
      text="unlock wallet"
      onClick={() => setShowWarning(true)}
      {...props}
    />,
  ];
};

export default UnlockButton;
