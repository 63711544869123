import React, { FunctionComponent } from "react";

const PlayButton: FunctionComponent<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ ...props }) => {
  return (
    <button {...props} className={["btn-rounded btn-play", props.className].join(" ")}>
      <svg className="btn-play-icon" viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 2.69409C0 1.10542 1.763 0.150923 3.09329 1.01936L31.1291 19.3216C32.3394 20.1118 32.3377 21.8855 31.1258 22.6733L15.7 32.7L3.09708 40.9682C1.76705 41.8407 0 40.8866 0 39.2959V2.69409Z"
          fill="none"
        />
      </svg>
    </button>
  );
};

export default PlayButton;
