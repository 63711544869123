import React, { FunctionComponent, useEffect, useState } from "react";
import useViewport from "src/hooks/useViewport";
import { ArrowBtnOrientation, ArrowButton } from "../Button";
import Check, { CheckVariant } from "../Check";
import { CSSTransition } from "react-transition-group";

export class ProgressStep {
  constructor(public text: string, public completed: boolean = false) {}
}

export class ProgressItem {
  constructor(public title: string, public steps: ProgressStep[]) {}
}

type ProgressListProps = {
  items: ProgressItem[];
  defaultActiveItemCount: number;
};

const ProgressList: FunctionComponent<ProgressListProps & React.HTMLAttributes<HTMLElement>> = ({ items, defaultActiveItemCount, ...props }) => {
  const { width, breakpoints } = useViewport();
  const [activeItem, setActiveItem] = useState({ count: defaultActiveItemCount, htmlElement: null });
  const [isTransitionActive, setIsTransitionActive] = useState(true);

  useEffect(() => {
    // after the first render we need to ensure that we have an active item html element
    // in order for the progress bar to be properly initialized
    if (!activeItem.htmlElement) {
      const activeHTMLElements = document.getElementsByClassName("progress-list-bar-item active");
      setActiveItem({ ...activeItem, htmlElement: activeHTMLElements[activeHTMLElements.length - 1] });
    }
  });

  useEffect(() => {
    if (!activeItem.htmlElement) {
      return;
    }
    let { htmlElement } = activeItem;

    // reset parent margin left on mobile
    if (width > breakpoints.md) {
      htmlElement.offsetParent.style.marginLeft = "0px";
      return;
    }
    // + htmlElement.offsetParent.offsetWidth / 8
    htmlElement.offsetParent.style.marginLeft = `${-htmlElement.offsetLeft + width / 2 - htmlElement.offsetWidth / 2}px`;
  });

  const progressStepHeight = width > breakpoints.md ? 56 : 56;
  const progressStepsContainerHeight = Math.max(...items.map((progressItem) => progressItem.steps.length)) * progressStepHeight;

  const displayProgressBarItems = () => {
    return items.map((progressItem, index) => (
      <div
        className={["progress-list-bar-item", activeItem.count >= index ? "active" : ""].join(" ")}
        onClick={(e) => {
          e.stopPropagation();
          setActiveItem({ count: index, htmlElement: e.currentTarget });
        }}
      >
        <div className={"item-dot"} />
        <div className="item-title">{progressItem.title}</div>
      </div>
    ));
  };

  const displayProgressListSteps = () => {
    return items[activeItem.count].steps.map((progressStep) => (
      <div className={["progress-step", progressStep.completed ? "completed" : ""].join(" ")}>
        <Check className="tw-w-4 tw-mr-2 tw-h-5 tw-mt-0.5" variant={progressStep.completed ? CheckVariant.Active : CheckVariant.Inactive} />
        <div>{progressStep.text}</div>
      </div>
    ));
  };

  const activateTransition = () => {
    setIsTransitionActive(false);
    setTimeout(() => setIsTransitionActive(true), 300);
  };

  return (
    <div className={props.className}>
      <div className="progress-list-wrapper md:tw-container md:tw-max-w-lg">
        <div className="progress-list-bar-container">
          <div className="progress-bar progress-list-bar">
            {displayProgressBarItems()}
            <div
              className="progress-bar-fill progress-list-fill"
              style={{ width: activeItem.htmlElement?.offsetLeft + activeItem.htmlElement?.offsetWidth / 2 }}
            />
          </div>
        </div>
        <div className="progress-mobile-arrow mobile-left md:tw-hidden">
          <ArrowButton
            className="tw-w-8 tw-h-8 tw-mr-auto"
            inactive={activeItem.count === 0}
            onClick={() => {
              setTimeout(() => setActiveItem({ count: Math.max(activeItem.count - 1, 0), htmlElement: null }), 300);
              activateTransition();
            }}
          />
        </div>
        <div className="progress-mobile-arrow mobile-right md:tw-hidden">
          <ArrowButton
            className="md:tw-flex tw-w-8 tw-h-8 tw-ml-auto"
            inactive={activeItem.count === items.length - 1}
            arrowOrientation={ArrowBtnOrientation.Right}
            onClick={() => {
              setTimeout(() => setActiveItem({ count: Math.min(activeItem.count + 1, items.length - 1), htmlElement: null }), 300);
              activateTransition();
            }}
          />
        </div>
      </div>

      <div
        className="progress-steps-container tw-mt-10 tw-px-5 tw-items-center tw-gap-4
        lg:tw-grid xl:tw-grid-cols-6 xl:tw-grid-rows-1 lg:tw-gap-6 lg:tw-grid-cols-6
        md:tw-grid md:tw-grid-cols-8 md:tw-grid-rows-1 md:tw-gap-4"
        style={{ height: progressStepsContainerHeight }}
      >
        <ArrowButton
          className="md:tw-flex tw-hidden tw-col-span-1 tw-w-8 tw-h-8 tw-ml-auto lg:tw-mr-auto md:tw-ml-auto"
          inactive={activeItem.count === 0}
          onClick={() => {
            setTimeout(() => setActiveItem({ count: Math.max(activeItem.count - 1, 0), htmlElement: null }), 300);
            activateTransition();
          }}
        />
        <CSSTransition in={isTransitionActive} timeout={600} classNames="roadmap-progress-list-transition" unmountOnExit>
          <div className="lg:tw-col-span-4 md:tw-col-span-6">
            {displayProgressListSteps()}
          </div>
        </CSSTransition>
        <ArrowButton
          className="tw-hidden tw-w-8 tw-h-8 tw-col-span-1 tw-mr-auto
            lg:tw-mr-auto md:tw-ml-auto md:tw-flex
          "
          inactive={activeItem.count === items.length - 1}
          arrowOrientation={ArrowBtnOrientation.Right}
          onClick={() => {
            setTimeout(() => setActiveItem({ count: Math.min(activeItem.count + 1, items.length - 1), htmlElement: null }), 300);
            activateTransition();
          }}
        />
      </div>
    </div>
  );
};

export default ProgressList;
