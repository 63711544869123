const contracts = {
  cake: {
    56: '0xAFb2997fE9a99022E61C7e01B974E0e3D7704B02',
    97: '',
  },
  masterChef: {
    56: '0x9572923F4DD78f337377e9657bE75fC7d87762d1',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
};

export default contracts;
