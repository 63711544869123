import React, { FunctionComponent, useState } from "react";

export enum ButtonVariant {
  Primary,
  Secondary,
}

export type ButtonProps = {
  inactiveBgColor?: string;
  variant?: ButtonVariant;
  text?: string;
};

const Button: FunctionComponent<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  variant = ButtonVariant.Primary,
  text,
  inactiveBgColor = "var(--button-inactive-background)",
  ...props
}) => {
  // TODO: refactor this component

  const computeBtnStyle = (hovered = false) => {
    const commonTextElementStyle = {};
    let textContainerStyle = {};
    let textElementStyle = {};

    if (hovered) {
      textContainerStyle = {
        background: variant === ButtonVariant.Secondary ? "var(--gradient-2)" : inactiveBgColor,
      };
      textElementStyle = {
        background: variant === ButtonVariant.Secondary ? inactiveBgColor : "var(--gradient-2)",
      };
    } else {
      textContainerStyle = {
        background: variant === ButtonVariant.Secondary ? inactiveBgColor : "var(--gradient-2)",
      };
      textElementStyle = {
        background: variant === ButtonVariant.Secondary ? "var(--gradient-2)" : inactiveBgColor,
      };
    }

    textElementStyle = {
      ...textElementStyle,
      ...commonTextElementStyle,
    };

    return { textContainerStyle, textElementStyle };
  };

  const [touchTriggered, setTouchTriggered] = useState(false);
  const [btnStyle, setBtnStyle] = useState(computeBtnStyle());

  return (
    <button {...props} className={"btn " + (variant === ButtonVariant.Primary ? "btn-primary" : "btn-secondary") + " " + (props.className || "")}>
      <div
        onMouseEnter={(e) => {
          // don't modify style if touch device;
          if (touchTriggered) {
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          setBtnStyle(computeBtnStyle(true));
        }}
        onTouchStart={(e) => {
          // Mobile workaround for styling
          e.stopPropagation();
          setTouchTriggered(true);
          setBtnStyle(computeBtnStyle(false));
        }}
        onMouseLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setBtnStyle(computeBtnStyle(false));
        }}
        className="btn-text-container"
        style={btnStyle.textContainerStyle}
      >
        <div style={btnStyle.textElementStyle} className="btn-text">
          {text || props.children}
        </div>
      </div>
    </button>
  );
};

export default Button;
