import React, { FunctionComponent } from "react";
import CountDownContentGuard from "src/components/CountDown";
import Logo from "src/components/Logo";
import SaleStatus from "src/components/SaleStatus";
import constants from "src/configs/constants";

const Footer: FunctionComponent = () => {
  return (
    <div className="footer-section tw-pb-10 tw-px-7">
      <div
        className="tw-container tw-max-w-screen-lg tw-grid tw-grid-cols-1 tw-grid-flow-row
        sm:tw-grid-cols-2
        lg:tw-grid-cols-4 lg:tw-gap-x-10"
      >
        <Logo className="tw-mx-auto sm:tw-col-span-2 lg:tw-col-span-1" />
        <div className="sm:tw-mt-4 lg:tw-mt-0 tw-mt-7">
          <div className="footer-section-title">We’re on Social Media</div>
          <div className="tw-flex tw-flex-col tw-flex-wrap">
            <a className="footer-url" target="_blank" rel="noreferrer" href="https://www.facebook.com/slapameme/">
              Facebook
            </a>
            <a className="footer-url" target="_blank" rel="noreferrer" href="https://twitter.com/slapameme">
              Twitter
            </a>
            <a className="footer-url" target="_blank" rel="noreferrer" href="https://www.reddit.com/r/slapameme/">
              Reddit
            </a>
            <a className="footer-url" target="_blank" rel="noreferrer" href="https://t.me/slapameme">
              Telegram
            </a>
            <a className="footer-url" target="_blank" rel="noreferrer" href="https://discord.gg/slapameme">
              Discord
            </a>
          </div>
        </div>
        <div className="tw-mt-7 sm:tw-mt-4 lg:tw-grid lg:tw-col-span-2 lg:tw-grid-cols-4 lg:tw-mt-0">
          <div>
            <div className="footer-section-title">RESOURCES</div>
            <a className="footer-url" target="_blank" rel="noreferrer" href={constants.whitepaperUrl}>
              Whitepaper
            </a>
          </div>
          <div className="tw-mt-7 sm:tw-mt-4 lg:tw-mt-0 lg:tw-col-span-4">
            <CountDownContentGuard className="tw-mt-7 sm:tw-mt-4 lg:tw-col-span-4 lg:tw-mt-0" toUnixTime={constants.unixSaleStartTime}>
              <SaleStatus className="footer-sale-status tw-mt-7 sm:tw-mt-4 lg:tw-col-span-3 lg:tw-mt-4" />
            </CountDownContentGuard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
