import React, { FunctionComponent } from "react";

export enum ArrowBtnOrientation {
  Left,
  Right,
}

type Props = {
  arrowOrientation?: ArrowBtnOrientation;
  inactive?: boolean;
};

const ArrowButton: FunctionComponent<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  arrowOrientation = ArrowBtnOrientation.Left,
  inactive = false,
  ...props
}) => {
  return (
    <button {...props} className={["btn-rounded", inactive ? "btn-inactive" : "", props.className].join(" ")}>
      <img alt="" src="/static/svg/arrow.svg" className={[arrowOrientation === ArrowBtnOrientation.Right ? "arrow-right" : ""].join(" ")} />
    </button>
  );
};

export default ArrowButton;
