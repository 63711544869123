import React from "react";
import { ModalProvider } from "@pancakeswap-libs/uikit";
// import bsc, { UseWalletProvider } from '@binance-chain/bsc-use-wallet'
import * as bsc from "@binance-chain/bsc-use-wallet";
import { Provider } from "react-redux";
import getRpcUrl from "Wallet/utils/getRpcUrl";
import { ThemeContextProvider } from "Wallet/contexts/ThemeContext";
import { BlockContextProvider } from "Wallet/contexts/BlockContext";
import { RefreshContextProvider } from "Wallet/contexts/RefreshContext";
import { WalletDataContextPrivider } from "Wallet/contexts/WalletDataContext";
import store from "./state";

const Providers: React.FC = ({ children }) => {
  const rpcUrl = getRpcUrl();
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID);
  return (
    <Provider store={store}>
      <ThemeContextProvider>
        <bsc.UseWalletProvider
          chainId={chainId}
          connectors={{
            walletconnect: { rpcUrl },
            bsc,
          }}
        >
          <BlockContextProvider>
            <RefreshContextProvider>
              <WalletDataContextPrivider>
                <ModalProvider>{children}</ModalProvider>
              </WalletDataContextPrivider>
            </RefreshContextProvider>
          </BlockContextProvider>
        </bsc.UseWalletProvider>
      </ThemeContextProvider>
    </Provider>
  );
};

export default Providers;
