import React, { FunctionComponent } from "react";

type Props = {
  filledPercentage: number;
};

const ProgressBar: FunctionComponent<Props & any> = ({ filledPercentage }) => {
  return (
    <div className="progress-bar tw-mx-auto">
      <div className="progress-bar-fill" style={{ width: `${filledPercentage}%` }} />
    </div>
  );
};

export default ProgressBar;
