import React, { FunctionComponent } from "react";
import CountDownContentGuard from "src/components/CountDown";
import SectionContent from "src/components/SectionContent";
import TakePart from "./TakePart";
import constants from "src/configs/constants";
import Wallet from "Wallet/ifo";

const Purchase: FunctionComponent<React.HTMLAttributes<HTMLElement>> = ({ ...props }) => {
  return (
    <div {...props}>
      <SectionContent
        className="section-container-empty"
        title="Purchase now"
        text="The first token ever created for the events industry. XXX Coin technology will be used for cruise ships, small and large events,resorts, adventure parks but also in many other situations. The #CASHLESS revolution starts today."
      >
        <div className="md:tw-mt-15 tw-mt-7 tw-container tw-max-w-screen-lg tw-grid md:tw-grid-cols-2 tw-grid-cols-1">
          <TakePart className="tw-order-last tw-mt-7 md:tw-order-none md:tw-mt-0" />
          <CountDownContentGuard title="the countdown has begun" toUnixTime={constants.unixSaleStartTime}>
            <Wallet className="tw-max-w-auto md:tw-max-w-full sm:tw-mx-auto" />
          </CountDownContentGuard>
        </div>
      </SectionContent>
    </div>
  );
};

export default Purchase;
