import React, { FunctionComponent } from "react";
import SectionTitle from "./SectionTitle";

type Props = {
  title?: string;
  subtitle?: string;
  text?: string;
};

const SectionContent: FunctionComponent<Props & React.HTMLAttributes<HTMLElement>> = ({ title, subtitle, text, children, ...props }) => {
  return (
    <div className={props.className}>
      <div className={"tw-container tw-max-w-screen-lg tw-text-center tw-flex tw-flex-col tw-items-center"}>
        <SectionTitle title={title} subtitle={subtitle} text={text} />
      </div>
      {children}
    </div>
  );
};

export default SectionContent;
