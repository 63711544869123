import React, { FunctionComponent } from "react";
import Button, { ButtonVariant } from "src/components/Button";
import SimpleList from "src/components/SimpleList";

const TakePart: FunctionComponent<React.HTMLAttributes<HTMLElement>> = ({ className }) => {
  return (
    <div className={["take-part-container tw-px-5", className].join(" ")}>
      <div className="tw-font-bold tw-text-white tw-text-xl">How to take part</div>
      <div className="md:tw-mt-8 tw-mt-6">
        <SimpleList title="Before pre-sale" items={["Buy BNB", "Wait for the pre-sale to start"]} />
        <Button
          inactiveBgColor="#1D212D"
          className="tw-mt-2"
          variant={ButtonVariant.Primary}
          onClick={() => window.open("https://exchange.pancakeswap.finance/#/swap", "_blank")}
        >
          buy BNB
        </Button>
      </div>
      <SimpleList
        className="md:tw-mt-8 tw-mt-6"
        title="During pre-sale"
        items={[
          "While the sale is live, press the Contribute button and input the amount of BNB",
          "Confirm the transaction in your wallet",
          "Min amount 0.1 BNB",
          "Max Amount 7 BNB",
        ]}
      />
      <SimpleList className="md:tw-mt-8 tw-mt-6" title="After pre-sale" items={["Tokens will distribute to your wallet after the pre-sale"]} />
    </div>
  );
};

export default TakePart;
