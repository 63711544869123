import React, { FunctionComponent } from "react";

type Props = {
  title: string;
  subtitle?: string;
  text?: string;
};

const SectionTitle: FunctionComponent<Props> = ({ title, subtitle, text }) => {
  return (
    <>
      <div className="section-title inside-container-padding">{title}</div>
      {subtitle && <div className="section-subtitle tw-mt-1 inside-container-padding">{subtitle}</div>}
      <div className="separator-primary tw-mt-3 tw-mb-4 inside-container-padding" />
      {text && <div className="section-text inside-container-padding">{text}</div>}
    </>
  );
};
export default SectionTitle;
