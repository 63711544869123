import React, { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import SaleStatus from "src/components/SaleStatus";
import constants from "src/configs/constants";

const SalePopup: React.FC = () => {
  const calculateTimeLeft = () => {
    const diff = +targetDate - +new Date();

    return diff < 0 ? 0 : diff;
  };

  useEffect(() => {
    if (!timeLeft) {
      return;
    }

    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft);
    }, 1000);

    return () => clearTimeout(timer);
  });

  const targetDate = new Date(constants.unixSaleStartTime * 1000);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [popupDisplayable, setPopupDisplayable] = useState(true);
  const { anchorsEnum } = constants;

  return (
    <Popup displayable={!timeLeft ? popupDisplayable : false} displayAnchor={anchorsEnum.ALLOCATION} hideAnchor={anchorsEnum.PURCHASE}>
      <SaleStatus onPurchaseClicked={() => setPopupDisplayable(false)} />
    </Popup>
  );
};

export default SalePopup;
