import React, { FunctionComponent } from "react";
import withWalletData from "Wallet/hooks/withWalletData";
import { AnchorButton } from "../Button";
import ProgressBar from "../ProgressBar";
import constants from "src/configs/constants";

type Props = {
  onPurchaseClicked: () => void;
};

const SaleStatus: FunctionComponent<Props & any> = ({ onPurchaseClicked, ...props }) => {
  const soldPercentage = props.walletData.hardCapProgress.toFixed(2);
  return (
    <div {...props}>
      <div className="sale-status-title">pre-sale live status</div>
      <ProgressBar filledPercentage={soldPercentage} />
      <div className="tw-font-bold tw-mt-2 tw-mb-6">{soldPercentage}% sold</div>
      <AnchorButton inactiveBgColor="var(--card-primary-background)" anchor={constants.anchorsEnum.PURCHASE} onClick={onPurchaseClicked}>
        purchase now
      </AnchorButton>
    </div>
  );
};

export default withWalletData(SaleStatus);
