import React, { FunctionComponent } from "react";

type Props = {
  title: string;
  text: string;
};

const AtomicData: FunctionComponent<Props & React.HTMLAttributes<HTMLElement>> = ({ title, text, ...props }) => {
  return (
    <div className={["atomic-data", props.className].join(" ")}>
      <div className="title">{title}</div>
      <div className="separator-primary tw-my-2" />
      <div className="subtitle">{text}</div>
    </div>
  );
};
export default AtomicData;
