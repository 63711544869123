import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import { useIdoContract } from "Wallet/hooks/useContract";
import useBlock from "Wallet/hooks/useBlock";
import { IfoStatus } from "Wallet/config/constants/types";
import { ifosConfig } from "Wallet/config/constants";

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const activeIfo = ifosConfig.find((ifo) => ifo.isActive);

const WalletDataContext = React.createContext({
  ifo: activeIfo,
  walletData: {
    blocksRemaining: 0,
    endBlockNum: 0,
    hardCap: new BigNumber(0),
    hardCapProgress: 0,
    isLoading: true,
    isOpen: null,
    progress: 0,
    secondsUntilEnd: 0,
    secondsUntilStart: 0,
    softCap: new BigNumber(0),
    softCapProgress: 0,
    startBlockNum: 0,
    status: null,
    tokensPerBnb: new BigNumber(0),
    weiRaised: new BigNumber(0),
    softCapReached: false,
    finalized: false,
  },
});

const getStatus = (currentTime: number, startTime: number, endTime: number): IfoStatus | null => {
  if (currentTime < startTime) {
    return "coming_soon";
  }

  if (currentTime >= startTime && currentTime <= endTime) {
    return "live";
  }

  if (currentTime > endTime) {
    return "finished";
  }

  return null;
};

const WalletDataContextPrivider = ({ children }) => {
  const ifo = activeIfo;
  const [state, setState] = useState({
    blocksRemaining: 0,
    endBlockNum: 0,
    hardCap: new BigNumber(0),
    hardCapProgress: 0,
    isLoading: true,
    isOpen: null,
    progress: 0,
    secondsUntilEnd: 0,
    secondsUntilStart: 0,
    softCap: new BigNumber(0),
    softCapProgress: 0,
    startBlockNum: 0,
    status: null,
    tokensPerBnb: new BigNumber(0),
    weiRaised: new BigNumber(0),
    softCapReached: false,
    finalized: false,
    account: null,
    presaleContract: null,
  });
  const { account } = useWallet();
  const presaleContract = useIdoContract(ifo.address[CHAIN_ID]);

  const currentBlock = useBlock();

  useEffect(() => {
    const fetchProgress = async () => {
      const [startTime, endTime, softCap, hardCap, tokensPerBnb, weiRaised, isOpen, softCapReached, finalized] = await Promise.all([
        presaleContract.methods.startTime().call(),
        presaleContract.methods.endTime().call(),
        presaleContract.methods.softCap().call(),
        presaleContract.methods.hardCap().call(),
        presaleContract.methods.tokensPerBnb().call(),
        presaleContract.methods.weiRaised().call(),
        presaleContract.methods.isOpen().call(),
        presaleContract.methods.softCapReached().call(),
        presaleContract.methods.finalized().call(),
      ]);

      const softCapProgress = (weiRaised / softCap) * 100;
      const hardCapProgress = (weiRaised / hardCap) * 100;

      const startBlockNum = parseInt(startTime, 10);
      const endBlockNum = parseInt(endTime, 10);
      const blocksRemaining = endBlockNum - currentBlock;

      const currentTime = Math.round(Date.now() / 1000);

      // const status = getStatus(currentBlock, startBlockNum, endBlockNum)
      const status = getStatus(currentTime, startTime, endTime);

      // Calculate the total progress until finished or until start
      const progress =
        currentTime > startTime ? ((currentTime - startTime) / (endTime - startTime)) * 100 : ((currentTime - endTime) / (startTime - endTime)) * 100;

      setState({
        blocksRemaining,
        endBlockNum,
        hardCap,
        hardCapProgress: hardCapProgress > 100 ? 100 : hardCapProgress,
        isLoading: false,
        isOpen,
        progress,
        secondsUntilEnd: endTime - currentTime,
        secondsUntilStart: startTime - currentTime,
        softCap,
        softCapProgress: softCapProgress > 100 ? 100 : softCapProgress,
        startBlockNum,
        status,
        tokensPerBnb,
        weiRaised,
        softCapReached,
        finalized,
        account,
        presaleContract,
      });
    };

    fetchProgress();
  }, [currentBlock, presaleContract, account, setState]);

  return <WalletDataContext.Provider value={{ ifo: activeIfo, walletData: state }}>{children}</WalletDataContext.Provider>;
};

export { WalletDataContext, WalletDataContextPrivider };
