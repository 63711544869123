import React, { FunctionComponent } from "react";

type Props = {
  title: string;
  items: Array<string>;
};

const SimpleList: FunctionComponent<Props & React.HTMLAttributes<HTMLElement>> = ({ title, items, className }) => {
  return (
    <div className={className}>
      <div className="simple-list-title tw-mb-2">{title}</div>
      {items.map((itemText) => (
        <div className="simple-list-item">{itemText}</div>
      ))}
    </div>
  );
};

export default SimpleList;
