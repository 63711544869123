import React, { FunctionComponent } from "react";

export enum CheckVariant {
  Active,
  Inactive,
}

type Props = {
  variant?: CheckVariant;
};

const Check: FunctionComponent<Props & React.HTMLAttributes<HTMLElement>> = ({ variant = CheckVariant.Inactive, ...props }) => {
  return variant === CheckVariant.Active ? (
    <img {...props} alt="" src="/static/svg/check_active.svg" />
  ) : (
    <img {...props} alt="" src="/static/svg/check_inactive.svg" />
  );
};

export default Check;
