import { Ifo } from './types'
import constants from "src/configs/constants";

const ifos: Ifo[] = [
  {
    id: 'xxx',
    address: {
      56: process.env.REACT_APP_SMART_CONTRACT_ID,
      97: process.env.REACT_APP_SMART_CONTRACT_ID,
    },
    isActive: true,
    name: 'XXX Coin',
    token: 'XXX',
    subTitle: 'XXX Coin - The rocket fuel for your event! The first token ever created for the events industry.',
    description:
      'XXXCoin technology will be used for cruise ships, small and large events,resorts, adventure parks but also in many other situations. The #CASHLESS revolution starts today.',
    saleAmount: process.env.REACT_APP_SALE_AMOUNT,
    raiseAmount: '400',
    projectSiteUrl: 'https://www.xxxcoin.org/',
    currency: 'BNB',
    currencyAddress: process.env.REACT_APP_CURRENCY_ID,
    tokenDecimals: 9,
    // DO NOT FORGET TO SET THESE FIELDS
    maxContribution: '7',
    minContribution: '0.1',
  },
]

export default ifos
