import React, { FunctionComponent } from "react";

type Props = {
  percentage: number;
};

const PercentageCard: FunctionComponent<Props & React.HTMLAttributes<HTMLElement>> = ({ percentage, ...props }) => {
  return (
    <div className={["card-primary centered-content percentage-card", props.className].join(" ")}>
      <div className="percentage tw-mb-2">{percentage}%</div>
      <div className="separator-secondary tw-mb-2" />
      <div className="tw-text-center">{props.children}</div>
    </div>
  );
};

export default PercentageCard;
