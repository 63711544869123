import React, { FunctionComponent, useState } from "react";
import NavbarBgLayerSVG from "./NavbarBgLayerSVG";
import constants from "src/configs/constants";

type NavbarSecondaryItem = {
  name: string;
  url: string;
};

const { anchorsEnum } = constants;

const NAVBAR_SECONDARY_ITEMS: NavbarSecondaryItem[] = [
  { name: "About us", url: anchorsEnum.MISSION },
  { name: "Coin allocation", url: anchorsEnum.ALLOCATION },
  { name: "Roadmap", url: anchorsEnum.ROADMAP },
  { name: "Tokenomics", url: anchorsEnum.TOKENOMICS },
  { name: "Team", url: anchorsEnum.TEAM },
];

type Props = {};

const Navbar: FunctionComponent<Props & any> = () => {
  const [isMenuToggled, setIsMenuToggled] = useState<boolean>(false);

  const menuButtonClicked = () => {
    setIsMenuToggled(!isMenuToggled);
  };

  const formattedAnchorHref = (anchor) => {
    return `#${anchor}`;
  };

  return (
    <div>
      {/* Desktop */}
      <div className="tw-z-10 tw-hidden lg:tw-flex tw-my-10 tw-justify-center tw-items-center tw-text-xs xl:tw-text-sm">
        {NAVBAR_SECONDARY_ITEMS.map((navbarItem) => (
          <a href={formattedAnchorHref(navbarItem.url)} className="navbar-item navbar-item-secondary tw-mx-3 xl:tw-mx-4">
            {navbarItem.name}
          </a>
        ))}
        <a
          href={formattedAnchorHref(anchorsEnum.PURCHASE)}
          className="navbar-item navbar-item-primary tw-mx-3 xl:tw-mx-4 tw-px-4 xl:tw-px-6 tw-py-1.5 xl:tw-py-2 tw-flex tw-items-center"
        >
          <span>Buy XXX Coin</span>
          <img height="18px" width="18px" alt="" src="/static/svg/arrow-light.svg" className="arrow-right" />
        </a>
      </div>

      {/* Tablet & Mobile */}

      {/* Navbar (which will come below when the menu is toggled */}
      <div
        className={`tw-flex lg:tw-hidden \
                       tw-mx-6 lg:tw-mx-10 tw-my-6 lg:tw-my-10 \
                       tw-h-5 \
                       tw-justify-end \
                       tw-text-xs xl:tw-text-sm`}
      >
        {!isMenuToggled ? <img alt="" src="/static/svg/menu.svg" className="tw-cursor-pointer tw-h-5 tw-w-5" onClick={() => menuButtonClicked()} /> : null}
      </div>

      {/* Toggled Menu */}
      <div className="tw-block lg:tw-hidden">
        {isMenuToggled ? (
          <>
            <div className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-z-10">
              <NavbarBgLayerSVG className="tw-absolute" />
              <div className="tw-absolute tw-w-full tw-right-6 lg:tw-rigth-10 tw-top-6 lg:tw-top-10 tw-flex tw-flex-col tw-items-end">
                <img alt="" src="/static/svg/close.svg" className="tw-cursor-pointer tw-h-5 tw-w-5" onClick={() => menuButtonClicked()} />
                <div className="tw-flex tw-flex-col tw-items-end tw-mt-3">
                  {NAVBAR_SECONDARY_ITEMS.map((navbarItem) => (
                    <a href={formattedAnchorHref(navbarItem.url)} onClick={() => setIsMenuToggled(false)} className="navbar-item navbar-item-secondary tw-my-3 sm:tw-my-2">
                      {navbarItem.name}
                    </a>
                  ))}
                  <a
                    href={formattedAnchorHref(anchorsEnum.PURCHASE)}
                    onClick={() => setIsMenuToggled(false)}
                    className="navbar-item navbar-item-primary tw-my-3 sm:tw-my-2 tw-px-4 xl:tw-px-6 tw-py-1.5 xl:tw-py-2 tw-flex tw-items-center"
                  >
                    <span>Buy XXX Coin</span>
                    <img height="18px" width="18px" alt="" src="/static/svg/arrow-light.svg" className="arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Navbar;
