import React, { FunctionComponent } from "react";

const GradientLayerSVG: FunctionComponent<React.HTMLAttributes<HTMLOrSVGElement>> = ({ className }) => {
  return (
    <svg className={className} viewBox="24 0 1440 257" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path d="M24 12V12C498.564 114.163 989.436 114.163 1464 12V12V137V137C987.288 216.452 500.712 216.452 24 137V137V12Z" fill="#222533" />
      </g>
      <g filter="url(#filter1_d)">
        <path d="M24 12V12C498.902 110.938 989.098 110.938 1464 12V12V107V107C989.082 186.153 502.956 166.739 35.8622 49.9656L24 47V12Z" fill="#222533" />
      </g>
      <g filter="url(#filter2_d)">
        <path d="M24 12V12C498.902 110.938 989.098 110.938 1464 12V12V17V17C989.098 115.938 498.902 115.938 24 17V17V12Z" fill="url(#paint0_linear)" />
      </g>
      <defs>
        <filter id="filter0_d" x="0" y="0" width="1488" height="232.589" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.027451 0 0 0 0 0.0784314 0 0 0 0.36 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter1_d" x="0" y="0" width="1488" height="190.751" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.027451 0 0 0 0 0.0784314 0 0 0 0.36 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter2_d" x="0" y="0" width="1488" height="127.203" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.027451 0 0 0 0 0.0784314 0 0 0 0.36 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient id="paint0_linear" x1="24" y1="12" x2="486.721" y2="766.415" gradientUnits="userSpaceOnUse">
          <stop stop-color="#ED2393" />
          <stop offset="1" stop-color="#FDBD6E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GradientLayerSVG;
