import React from "react";
import ReactDOM from "react-dom";
import "styles/index.css";
import App from "./App";
import WalletProviders from "Wallet/WalletProviders";
import { ViewportProvider } from "./context/ViewportContext";
  
{/* We need wallet providers for real-time wallet data - inherited from the old-app logic */}

ReactDOM.render(
    <WalletProviders>
      <ViewportProvider>
        <App />
      </ViewportProvider>
    </WalletProviders>,
  document.getElementById("root")
);
