import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import Button from "src/components/Button";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: string;
  label?: string;
  buttonLabel: string;
  disabled?: boolean;
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LabelButton: React.FC<Props> = ({ value, label, buttonLabel, onClick, disabled = false }) => {
  return (
    <div>
      {label && <div className="contribute-label">{label}</div>}
      <ButtonWrapper>
        <div className="tw-text-base">{value}</div>
        <Button inactiveBgColor="var(--card-primary-background)" onClick={onClick} disabled={disabled} text={buttonLabel}>
          {buttonLabel}
        </Button>
      </ButtonWrapper>
    </div>
  );
};

export default LabelButton;
