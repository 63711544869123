import React, { FunctionComponent } from "react";
import GradientLayerSVG from "./GradientLayerSVG";

type Props = {
  imgSrc?: string;
  transparentBackground?: boolean;
};

const RoundedSection: FunctionComponent<Props> = ({ imgSrc, transparentBackground, children }) => {
  // TODO: refactor this component, pls - no hardcoded values
  const separatorTranslationDelta = 95;
  const childrenContainerStyle = {
    background: transparentBackground ? "transparent" : imgSrc ? `url(${imgSrc})` : "var(--background-primary)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingBottom: `${separatorTranslationDelta}px`,
  };
  const separatorContainerStyle = {
    transform: `translateY(-${separatorTranslationDelta}px)`,
    height: `calc(var(--rounded-separator-height) - ${separatorTranslationDelta}px)`,
  };

  return (
    <>
      <div style={childrenContainerStyle}>{children}</div>
      <div className="empty-layer-container">
        <div className="tw-relative" style={separatorContainerStyle}>
          <GradientLayerSVG className="layer empty-layer" />
        </div>
      </div>
    </>
  );
};

export default RoundedSection;
