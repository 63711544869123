import React, { FunctionComponent } from "react";
import SectionContent from "src/components/SectionContent";
import Line from "src/components/Line";
import YTVideo from "src/components/YTVideo";
import { RoundedSection } from "src/components/RoundedSection";

const Watch: FunctionComponent = () => {
  return (
    <RoundedSection>
      <SectionContent
        className="section-container-no-horizontal-padding tw-pt-14 tw-pb-5 md:tw-pt-15 tw-max-w-full"
        title="watch our video"
        text="Don't miss out this opportunity!"
      >
        <div className="tw-relative tw-mt-4 md:tw-mt-7 sm:tw-mt-8">
          <div className="cross-lines-container top-container">
            <Line className="bg-line rotated-negative" />
            <Line className="bg-line rotated-positive" />
          </div>
          <div className="cross-lines-container bottom-container">
            <Line className="bg-line rotated-negative" />
            <Line className="bg-line rotated-positive" />
          </div>
          <div className="inside-container-padding tw-container tw-max-w-screen-lg tw-mx-auto">
            <YTVideo className="tw-mx-auto" thumbnailSrc="/static/images/video_thumbnail.png" videoID="123123123" />
          </div>
        </div>
      </SectionContent>
    </RoundedSection>
  );
};

export default Watch;
