import React, { FunctionComponent, useState } from "react";
import useViewport from "src/hooks/useViewport";
import { PlayButton } from "../Button";

type Props = {
  videoID: string;
  thumbnailSrc: string;
};

const YTVideo: FunctionComponent<Props & React.HTMLAttributes<HTMLElement>> = ({ videoID, thumbnailSrc, className }) => {
  const [displaysThumbnail, setDisplaysThumbnail] = useState(true);
  const { width, breakpoints } = useViewport();

  return (
    <div className={["yt-video-element", className].join(" ")}>
      <div className="yt-video-container">
        {width > breakpoints.sm && (
          <div className="video-overlap" style={{ visibility: displaysThumbnail ? "visible" : "hidden" }}>
            <PlayButton className="video-overlap-btn" onClick={() => setDisplaysThumbnail(false)} />
            {/* Thumbnail (other than the default one of the video) if needed. */}
            {/*<img className="video-item" alt="" src={thumbnailSrc} onClick={() => setDisplaysThumbnail(false)} />*/}
          </div>
        )}
        <iframe
          className="video-item"
          src={`https://www.youtube.com/embed/${videoID}?rel=0&autoplay=${displaysThumbnail ? 0 : 1}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </div>
    </div>
  );
};

export default YTVideo;
