import React, { FunctionComponent } from "react";
import useViewport from "src/hooks/useViewport";
import constants from "src/configs/constants";

type Props = {
  imgSrc: string;
  name: string;
  jobTitle: string;
  promotionUrl?: string;
  urlText?: string;
  email?: string;
  linkedinUrl?: string;
  twitterUrl?: string;
  facebookUrl?: string;
};

const TeamMemberCard: FunctionComponent<Props> = ({ imgSrc, name, jobTitle, promotionUrl, linkedinUrl, twitterUrl, facebookUrl, urlText }) => {
  const { width, breakpoints } = useViewport();
  const getSocialButtons = () => {
    let secondarySocialButton = (
      <img alt="" className="team-social-img" src="/static/svg/twitter.svg" onClick={() => window.open(twitterUrl || constants.xxxTwitter, "_blank")} />
    );

    if (facebookUrl) {
      secondarySocialButton = <img alt="" className="team-social-img" src="/static/svg/facebook.svg" onClick={() => window.open(facebookUrl, "_blank")} />;
    }

    return [
      <img alt="" className="team-social-img" src="/static/svg/linkedin.svg" onClick={() => linkedinUrl && window.open(linkedinUrl, "_blank")} />,
      secondarySocialButton,
    ];
  };

  return (
    <>
      {width >= breakpoints.sm ? (
        <div className="tw-hidden sm:tw-flex card-primary centered-content team-member-card tw-p-5">
          {/* Right */}
          <div>
            <img className="tw-rounded-full team-member-card-img" alt="" src={imgSrc} />
          </div>
          <div className="team-social-section tw-flex tw-mt-3">{getSocialButtons()}</div>
          <div className="separator-secondary tw-mt-3 tw-mb-2" />

          {/* Left */}
          <div>
            <div className="team-member-card-name">{name}</div>
            <div className="team-member-card-job-title">{jobTitle}</div>
            {promotionUrl && (
              <a target="_blank" rel="noreferrer" href={promotionUrl} className="team-member-card-email">
                {urlText}
              </a>
            )}
          </div>
        </div>
      ) : (
        <div className="tw-relative tw-flex sm:tw-hidden card-small tw-p-4 tw-items-center">
          <div>
            <img className="tw-rounded-full team-member-card-img-small" alt="" src={imgSrc} />
          </div>
          <div className="separator-vertical tw-ml-4 tw-mr-4" />
          <div>
            <div className="team-member-card-name tw-text-sm">{name}</div>
            <div className="team-member-card-job-title tw-text-sm">{jobTitle}</div>
            {promotionUrl && (
              <a target="_blank" rel="noreferrer" href={promotionUrl} className="team-member-card-email tw-text-sm">
                {urlText}
              </a>
            )}
            <div className="mobile-social-container tw-flex tw-justify-between tw-mt-4">{getSocialButtons()}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamMemberCard;
