import React, { FunctionComponent } from "react";

const BgLayerSVG: FunctionComponent<React.HTMLAttributes<HTMLOrSVGElement>> = () => {
  return (
    <svg width="100%" height="667" preserveAspectRatio="none" viewBox="0 0 375 667" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_nav)">
        <path d="M0 4H375V488L347.945 499.012C194.862 561.319 23.9077 463.539 0 300V300V4Z" fill="url(#paint0_linear_nav)" />
      </g>
      <g filter="url(#filter1_d_nav)">
        <path d="M0 0H375V481.5L368.197 484.874C198.747 568.921 0 445.648 0 256.5V256.5V0Z" fill="#191B28" />
      </g>
      <defs>
        <filter id="filter0_d_nav" x="-24" y="-8" width="423" height="561.962" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.027451 0 0 0 0 0.0784314 0 0 0 0.36 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter id="filter1_d_nav" x="-24" y="-12" width="423" height="559.749" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.027451 0 0 0 0 0.0784314 0 0 0 0.36 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_nav" x1="0" y1="4" x2="435.859" y2="50.4516" gradientUnits="userSpaceOnUse">
          <stop stop-color="#ED2393" />
          <stop offset="1" stop-color="#FDBD6E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BgLayerSVG;
