import React, { FunctionComponent } from "react";
import SectionContent from "src/components/SectionContent";

const Mission: FunctionComponent<React.HTMLAttributes<HTMLElement>> = ({ ...props }) => {
  return (
    <div {...props}>
      <SectionContent
        className="section-container-empty"
        title="Mission is possible"
        subtitle="not a token, but a movement!"
        text="In an overflowing market of cryptocurrencies, it would be pointless to launch yet another entity, only slightly different from the others. Thankfully, XXX Coin stands out from the crowd as more than a crypto novelty, but as an ambition to connect a community that shares the same values, passions and lives by the same creed, that the future belongs to the brave!"
      />
    </div>
  );
};

export default Mission;
