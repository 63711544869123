import React, { FunctionComponent } from "react";
import Line from "src/components/Line";
import { RoundedSection } from "src/components/RoundedSection";
import SectionContent from "src/components/SectionContent";
import TeamMemberCard from "src/components/TeamMemberCard";

const Team: FunctionComponent<React.HTMLAttributes<HTMLElement>> = ({ ...props }) => {
  return (
    <div {...props}>
      <RoundedSection>
        <SectionContent
          className="section-container-no-horizontal-padding"
          title="Meet the team"
          text="One Coin to Reign Supreme... but for that to happen it takes a full orchestra of brains!"
        >
          <div className="sm:tw-mt-8 tw-mt-5">
            <div className="tw-relative">
              <Line className="bg-line rotated-negative" />
              <div className="inside-container-padding even-child-reverse tw-container tw-flex-wrap xl:tw-flex-nowrap tw-flex tw-flex-col sm:tw-flex-row tw-justify-center team-flex-gap">
                <TeamMemberCard
                  imgSrc="/static/images/team/ceo.jpg"
                  name="Javier Meza"
                  jobTitle="ceo"
                  linkedinUrl="https://www.linkedin.com/in/slapameme"
                  twitterUrl="https://twitter.com/slapameme"
                />
                <TeamMemberCard
                  imgSrc="/static/images/team/coo.jpg"
                  name="Momcilo Kujacic"
                  jobTitle="coo"
                  linkedinUrl="https://www.linkedin.com/in/slapameme"
                />
                <TeamMemberCard
                  imgSrc="/static/images/team/cmo.jpg"
                  name="Jelena Stakhov"
                  jobTitle="cmo"
                  linkedinUrl="https://www.linkedin.com/in/slapameme"
                />
                <TeamMemberCard
                  imgSrc="/static/images/team/cto.jpg"
                  name="James Richard"
                  jobTitle="cto"
                  linkedinUrl="https://www.linkedin.com/in/slapameme"
                />
              </div>
            </div>
            <div className="tw-relative">
              <Line className="bg-line rotated-positive" />
              <div className="inside-container-padding even-child-reverse tw-container tw-flex-wrap xl:tw-flex-nowrap tw-items-center tw-flex tw-flex-col sm:tw-flex-row tw-justify-center team-flex-gap">
                <TeamMemberCard
                  imgSrc="/static/images/team/dev1.jpg"
                  name="Aleksandra G."
                  jobTitle="frontend developer"
                  linkedinUrl="https://www.linkedin.com/in/slapameme"
                />
                <TeamMemberCard
                  imgSrc="/static/images/team/dev2.jpg"
                  name="Randolph A King"
                  jobTitle="blockchain developer"
                  linkedinUrl="https://www.linkedin.com/in/slapameme"
                />
              </div>
            </div>
          </div>
        </SectionContent>
      </RoundedSection>
    </div>
  );
};

export default Team;
