import React, { FunctionComponent, useState } from "react";
import { SectionTitle } from "../SectionContent";
import CountDown from "./CountDown";

type Props = {
  title?: string;
  toUnixTime: number;
};

// todo fix this
const CountDownContentGuard: FunctionComponent<Props & any> = ({ title, toUnixTime, className, children, ...props }) => {
  const [displayCountdown, setDisplayCountdown] = useState(true);

  return displayCountdown ? (
    <div className="tw-w-full">
      {title && <SectionTitle title={title} />}
      <CountDown toUnixTime={toUnixTime} className={className} onTimePassed={() => setDisplayCountdown(false)} />
    </div>
  ) : (
    children
  );
};

export default CountDownContentGuard;
