import React, { FunctionComponent } from "react";
import Button, { ButtonProps } from "./Button";

type Props = {
  anchor: string;
};

const AnchorButton: FunctionComponent<Props & ButtonProps & React.HTMLAttributes<HTMLButtonElement>> = ({ anchor, ...props }) => {
  return (
    <Button
      {...props}
      onClick={(e) => {
        document.getElementById(anchor)?.scrollIntoView();
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    />
  );
};

export default AnchorButton;
