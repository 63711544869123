import React, { FunctionComponent } from "react";
import Button, { AnchorButton, ButtonVariant } from "src/components/Button";
import CountDownContentGuard from "src/components/CountDown";
import Logo from "src/components/Logo";
import Navbar from "src/components/Navbar";
import { RoundedSection } from "src/components/RoundedSection";
import constants from "src/configs/constants";

const Hero: FunctionComponent = () => {
  return (
    <>
      <RoundedSection transparentBackground={true}>
        <Navbar />
        <div className="tw-max-w-screen-md tw-mx-auto tw-text-center tw-flex tw-flex-col tw-items-center tw-px-4">
          <Logo className="tw-mb-7" />
          <div className="section-title tw-max-w-screen-sm tw-mb-8">Rocket fuel for your event</div>
          <CountDownContentGuard className="countdown-hero" toUnixTime={constants.unixSaleStartTime}>
            <div className="tw-max-w-screen-sm">
              XXX Coin breaks through the crypto chains with a simple yet elaborate principle: Rethink, Reset and Redesign!
            </div>
          </CountDownContentGuard>
          {/* tw-mt-8   */}
          <div className="tw-flex tw-flex-wrap sm:tw-space-x-5 xs:tw-space-x-2.5 2xs:tw-space-x-1 tw-space-x-0">
            <AnchorButton
              inactiveBgColor="#07073F"
              className="tw-mt-8 tw-flex-grow"
              variant={ButtonVariant.Primary}
              text="purchase"
              anchor={constants.anchorsEnum.PURCHASE}
            />
            <Button
              inactiveBgColor="#07073F"
              className="tw-mt-8 tw-flex-grow"
              variant={ButtonVariant.Secondary}
              text="whitepaper"
              onClick={() => window.open(constants.whitepaperUrl, "_blank")}
            />
          </div>
        </div>
      </RoundedSection>
    </>
  );
};

export default Hero;
