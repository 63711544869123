import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

type Props = {
  displayable: boolean;
  hideAnchor?: string;
  displayAnchor?: string;
};

const Popup: React.FC<Props> = ({ children, displayable = true, displayAnchor, hideAnchor, ...props }) => {
  const [closed, setClosed] = useState(false);
  const [displayed, setDisplayed] = useState(false);

  const recomputeDisplayStatus = () => {
    const displaySectionEl = document.getElementById(displayAnchor);

    const windowMiddle = document.body.scrollTop + window.innerHeight / 2;

    if (windowMiddle >= displaySectionEl.offsetTop) {
      if (!hideAnchor) {
        setDisplayed(true);
        return;
      }

      const hideSectionEl = document.getElementById(hideAnchor);
      const isInHideSection = windowMiddle >= hideSectionEl.offsetTop && windowMiddle <= hideSectionEl.offsetTop + hideSectionEl.offsetHeight;
      setDisplayed(!isInHideSection);
    }
  };

  useEffect(() => {
    if (!displayable) {
      setDisplayed(false);
      return;
    }

    if (!closed) {
      document.body.addEventListener("scroll", recomputeDisplayStatus);
    }

    return () => {
      document.body.removeEventListener("scroll", recomputeDisplayStatus);
    };
  });

  return (
    <CSSTransition in={displayed} timeout={500} classNames="popup-container-transition" unmountOnExit>
      <div className="popup-element card-primary tw-px-6 tw-py-5">
        <div className="tw-relative tw-w-full">
          <img
            className="popup-close-icon"
            alt=""
            src="/static/svg/close_icon.svg"
            onClick={() => {
              document.body.removeEventListener("scroll", recomputeDisplayStatus);
              setClosed(true);
              setDisplayed(false);
            }}
          />
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

export default Popup;
