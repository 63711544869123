import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

type Props = {
  hasScrim?: boolean;
  hasCloseBtn?: boolean;
  show?: boolean;
  onConfirm?: () => void;
};

const Modal: React.FC<Props> = ({ show = false, hasScrim = true, hasCloseBtn = true, onConfirm = () => {}, children }) => {
  const [isDisplayed, setIsDisplayed] = useState(show);
  const modalContainerNode = document.getElementById("modal-placeholder");

  useEffect(() => {
    if (!modalContainerNode) {
      return;
    }

    if (show !== isDisplayed) {
      setIsDisplayed(show);
    }

    modalContainerNode.className = isDisplayed ? "modal-container" : "";
  });

  const component = (
    <CSSTransition in={isDisplayed} timeout={300} classNames="modal-transition" unmountOnExit>
      <>
        {hasScrim && <div className="modal-scrim" />}
        <div className="modal-card">
          <div className="tw-px-6 tw-py-6 tw-w-full">
            {hasCloseBtn && <img className="modal-close-icon" alt="" src="/static/svg/close_icon.svg" onClick={() => setIsDisplayed(false)} />}
            {children}
          </div>
        </div>
      </>
    </CSSTransition>
  );

  return modalContainerNode && ReactDOM.createPortal(component, modalContainerNode);
};

export default Modal;
