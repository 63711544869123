import React, { useState } from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { dark } from "@pancakeswap-libs/uikit";

const CACHE_KEY = "IS_DARK";

const ThemeContext = React.createContext({ isDark: null, toggleTheme: () => null });

const ThemeContextProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY);
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false;
  });

  const toggleTheme = () => {
    setIsDark((prevState) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState));
      return !prevState;
    });
  };

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider
        theme={{
          ...dark,
          colors: { ...dark.colors, primary: "#ddddee", borderColor: "transparent" },
          shadows: {
            ...dark.shadows,
            level1: "0px 12px 24px rgba(0, 7, 20, 0.36)",
            active: "0px 12px 24px rgba(0, 7, 20, 0.36)",
            success: "0px 12px 24px rgba(0, 7, 20, 0.36)",
            warning: "0px 12px 24px rgba(0, 7, 20, 0.36)",
            focus: "0px 12px 24px rgba(0, 7, 20, 0.36)",
            inset: "0px 12px 24px rgba(0, 7, 20, 0.36)",
          },
        }}
      >
        {children}
      </SCThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
