import React, { FunctionComponent } from "react";
import AtomicData from "src/components/AtomicData";
import { AnchorButton, ButtonVariant } from "src/components/Button";
import RoundedSection from "src/components/RoundedSection/RoundedSection";
import SectionContent from "src/components/SectionContent";
import useViewport from "src/hooks/useViewport";
import constants from "src/configs/constants";

const Numbers: FunctionComponent<React.HTMLAttributes<HTMLElement>> = ({ ...props }) => {
  const { width, breakpoints } = useViewport();

  return (
    <div {...props}>
      <RoundedSection transparentBackground={true}>
        <SectionContent className="section-container-bg" title="LET’S TALK NUMBERS">
          <div className="tw-container tw-max-w-screen-lg tw-flex tw-flex-col tw-text-center tw-mt-4 tw-items-center">
            <p>
              This is just the beginning! With this golden foundation, XXX Coin shoots for the sky and projects far into the future to prepare and launch the
              next release that will complete the XXX Coin Universe, with a wallet and a NFC card of its own! <b>We have liftoff!</b>
            </p>
            <AnchorButton
              inactiveBgColor="#12053D"
              className="tw-w-wide tw-mt-10"
              variant={ButtonVariant.Primary}
              text="purchase"
              anchor={constants.anchorsEnum.PURCHASE}
            />
            <div
              className="tw-mt-7 tw-w-full tw-mx-auto tw-grid tw-grid-flow-row tw-grid-cols-2 tw-gap-8
                          lg:tw-grid-cols-6
                          md:tw-grid-cols-3 md:tw-mt-7
                          sm:tw-grid-cols-3 "
            >
              <AtomicData className="tw-mx-auto" title="name" text="XXX Coin" />
              <AtomicData className="tw-mx-auto" title="token symbol" text="XXX" />
              <AtomicData className="tw-mx-auto" title="network" text="Binance Smart Chain" />
              <AtomicData className="tw-mx-auto" title="liquidity pool" text="Pancakeswap Liquidity Locked" />
              <AtomicData
                className="tw-mx-auto"
                title="total supply"
                text={width <= breakpoints.sm ? "One Quadrillion" : "One Quadrillion 1.000.000.000.000.000"}
              />
              <AtomicData className="tw-mx-auto" title="decimals" text="9" />
            </div>
          </div>
        </SectionContent>
      </RoundedSection>
    </div>
  );
};

export default Numbers;
