const constants: any = {
    unixSaleStartTime: process.env.REACT_APP_RELEASE_UNIX_TIME,
    xxxLinkedin: "#",
    xxxTwitter: "https://twitter.com/slapameme",
    whitepaperUrl: "https://token.slapameme.co/assets/Whitepaper.pdf",
    anchorsEnum: {
      MISSION: "mission",
      ALLOCATION: "allocation",
      ROADMAP: "roadmap",
      TOKENOMICS: "tokenomics",
      TEAM: "team",
      PURCHASE: "purchase",
      FOOTER: "footer",
      WATCH: "watch",
    }
  }

export default constants
