import React from "react";

const breakpoints = {
  xs: 375,
  sm: 600,
  md: 768,
  lg: 1024,
};

const ViewportContext = React.createContext({ width: window.innerWidth, height: window.innerHeight, breakpoints });

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  let currentTimeout;

  const handleWindowResize = () => {
    currentTimeout = setTimeout(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 500);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  });

  return <ViewportContext.Provider value={{ width, height, breakpoints }}>{children}</ViewportContext.Provider>;
};

export { ViewportContext, ViewportProvider };
